import React, { useEffect, useState } from 'react';
import BulkMessageSender from './BulkMessageSender'; // Import the BulkMessageSender component
import SalesMetrics from '../../../helperFunctions/DashboardScenesHelpers/SalesPageHelpers/SalesMetric';
import LeadManagement from '../../../helperFunctions/DashboardScenesHelpers/SalesPageHelpers/LeadManagement';
import SalesPipeline from '../../../helperFunctions/DashboardScenesHelpers/SalesPageHelpers/SalesPipeline';
import ConvertedLeadsChart from '../../../helperFunctions/DashboardScenesHelpers/SalesPageHelpers/ConvertedLeadsChart';
import SidePanel from '../../../components/SidePanel';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { AllLeadsPage } from '../../../helperFunctions/DashboardScenesHelpers/SalesPageHelpers/AllLeadsPage';
import { fetchLeadsData, fetchLeadsInfoData } from '../../../helperFunctions/DashboardScenesHelpers/SalesPageHelpers/SalesPageHelpers';


const SalesPage = ({ gymData }) => {
    const [isPanelExpanded,  setIsPanelExpanded ] = useState(false);
    const [leadsData,        setLeadsData       ] = useState(null);           // (converted, totalLeads, monthName)
    const [leadsInfoData,    setLeadsInfoData   ] = useState(null);           // (all leads information)
    const [loadingLeads,     setLoadingLeads    ] = useState(false);
    const [loadingLeadsInfo, setLoadingLeadsInfo] = useState(false);
    const [showAllLeads,     setShowAllLeads    ] = useState(false);

    
    const togglePanel = () => {
        setIsPanelExpanded(!isPanelExpanded);
    };

    useEffect(() => {
        const leadsDataFetch = async () => {
            setLoadingLeads(true);                  // Start loading

            try {
                const data = await fetchLeadsData(gymData);
                setLeadsData(data);                 // Update the state with fetched data
            } catch (error) {
                // console.error("Error fetching leads data:", error);
            } finally {
                setLoadingLeads(false);             // Stop loading after data is fetched or error is caught
            }
        };
    
        leadsDataFetch();
    }, [gymData]);                                  // Add gymData as a dependency to avoid infinite loops
    
    useEffect(() => {
        const leadsInfoDataFtech = async () => {
            setLoadingLeadsInfo(true);              // Start loading

            try {
                const data = await fetchLeadsInfoData(gymData);
                setLeadsInfoData(data);             // Update the state with fetched data
            } catch (error) {
                // console.error("Error fetching leads info data:", error);
            } finally {
                setLoadingLeadsInfo(false);         // Stop loading after data is fetched or error is caught
            }
        };
    
        leadsInfoDataFtech();
    }, [gymData]);                                  // Add gymData as a dependency to avoid infinite loops

    if(loadingLeads || loadingLeadsInfo) {
        return (
            <div className='flex h-full w-full justify-center items-center'>
                <LoadingSpinner />
            </div>
        )
    }


    return (
        <div className="flex flex-row w-full pl-8 pr-4">
            <div className="w-full">
                <p className="text-left text-3xl font-bold text-secondary">Sales</p>

                {!showAllLeads ? (
                    <div className='flex flex-col space-y-4 pt-4'>
                    <div className="flex flex-col lg:flex-row justify-between space-y-8 lg:space-y-0 lg:gap-x-8">
                        <SalesMetrics leadsData={leadsData} />
                        <ConvertedLeadsChart gymData={gymData} />
                        {/* <LeadManagement data={convertedData}  /> */}
                    </div>
                    <div className="flex flex-col lg:flex-row justify-between lg:gap-x-8">
                        <SalesPipeline gymData={gymData} leadsInfoData={leadsInfoData} setLeadsInfoData={setLeadsInfoData} setLeadsData={setLeadsData} setShowAllLeads={setShowAllLeads}/> 
                    </div>
                </div>
                ) : (
                    <AllLeadsPage gymData={gymData} leadsInfoData={leadsInfoData} setLeadsInfoData={setLeadsInfoData} setLeadsData={setLeadsData} setShowAllLeads={setShowAllLeads}/>
                )}

                
            </div>

            <SidePanel isExpanded={isPanelExpanded} togglePanel={togglePanel} />
        </div>
    );
};

export default SalesPage;
