import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { addNewLead, toggleContacted, toggleConverted, toggleInterested, updatePrice, updateRemarks } from './SalesPageHelpers';
import { timeFormat } from '../../userDatabaseHelper';
import { checkValidFormDetails } from '../FinanceComponents/AddRevenueHelper';
import UserProfile, { UserBasicDetailsModal } from '../MemberManagementComponents/UserProfile';

// Receives
// gymData          - to get the coreect path in the database
// leadsInfoData    - inforamtion of each and every individual lead
// setLeadsInfoData - to update the state locally
// setLeadsData     - to pass the updated data in the other components of this page 
// setShowAllLeads  - to toggle between All leads page and other components
const SalesPipeline = ({ gymData, leadsInfoData, setLeadsInfoData, setLeadsData, setShowAllLeads }) => {
    const [submitting           , setSubmitting           ] = useState(false);
    const [showEditRemarksModal , setShowEditRemarksModal ] = useState(false);
    const [selectedLead         , setSelectedLead         ] = useState(null); // State to track the selected lead
    const [showAddPriceModal    , setShowAddPriceModal    ] = useState(false);
    const [isModalOpen          , setIsModalOpen          ] = useState(false);
    const [showLeadsDetailsModal, setShowLeadDetailsModal ] = useState(false);

    const handleToggleContacted = async(lead) => {
        setSubmitting(true);
        await toggleContacted(lead, gymData, setLeadsInfoData, setSubmitting);
        setSubmitting(false);
    };

    const handleToggleInterested = async (lead) => {
        setSubmitting(true);
        await toggleInterested(lead, gymData, setLeadsInfoData, setSubmitting);
        setSubmitting(false);
    };

    const handleAddNewLead = async (event) => {
        event.preventDefault();
    
        setSubmitting(true);
    
        const newLead = {
            boughtMembership    : false,
            contacted           : event.target.contacted.checked, // Get the checkbox value
            converted           : event.target.converted.checked, // Get the checkbox value
            email               : event.target.email.value,
            interested          : event.target.interested.checked, // Get the checkbox value
            phoneNumber         : `+91${event.target.phoneNumber.value}`,
            signedIn            : null,
            tempUser            : '',
            timestamp           : new Date().toISOString(),
            userCity            : event.target.userCity.value.toLowerCase(),
            userName            : event.target.userName.value,
            remarks             : event.target.remarks.value,
        };

        const isValidForm = await checkValidFormDetails("New Lead", newLead);

        if(isValidForm === false) {
            alert("Please fill the details correctly");
            setSubmitting(false);
            return;
        }
    
        await addNewLead(newLead, gymData, setSubmitting);
    
        setSubmitting(false);
        setIsModalOpen(false); // Close the modal after submission
    }

    const handleRemarksUpdate = async (e, lead) => {
        e.preventDefault();
    
        setSubmitting(true);
    
        // Get the updated remarks from the form input
        const updatedRemarks = e.target.remarks.value;
    
        try {
            // Call Firestore function to update the remarks field
            await updateRemarks(gymData, lead, 'remarks', updatedRemarks);
    
            // Update local state after successfully updating the database
            setLeadsInfoData(prevData =>
                prevData.map((item) =>
                    item.email === lead.email ? { ...item, remarks: updatedRemarks } : item
                )
            );
    
        } catch (error) {
            // console.error("Error updating remarks:", error);
        }
    
        setSubmitting(false);
        setShowEditRemarksModal(false);  // Close the modal after submission
    };

    const handleAddPrice = async (e, lead) => {
        e.preventDefault();
    
        setShowAddPriceModal(false);  // Close the modal after submission
        setSubmitting(true);
        const membershipPrice = e.target.price.value;
    
        try {
            // Call Firestore function to update the remarks field
            await updatePrice(gymData, lead, 'membershipPrice', membershipPrice);
    
            // if the lead is already converted (true) then no need to set it to false
            if(!lead.converted) await toggleConverted(lead, gymData, setLeadsData, setLeadsInfoData, setSubmitting);
        
            // Update local state after successfully updating the database
            setLeadsInfoData(prevData =>
                prevData.map((item) =>
                    item.email === lead.email ? { ...item, membershipPrice: membershipPrice } : item
                )
            );
    
        } catch (error) {
            // console.error("Error Adding Price:", error);
        }
    
        setSubmitting(false);
    }

    return (
        <div className="flex flex-col pt-2 w-full lg:w-7/8">
            <div className="flex justify-between items-center">
                <p className="text-sm md:text-lg text-secondary font-bold text-left p-2">Sales Pipeline</p>
                <button 
                    className='text-tertiary font-semibold text-sm md:text-base bg-secondary px-2 md:px-4 py-1 rounded-md md:rounded-xl mr-2 hover:scale-105 hover:shadow-lg hover:shadow-primary transition-all duration-300'
                    onClick={() => setIsModalOpen(true)}
                >
                    Add New Lead
                </button>
            </div>
            <div className="flex flex-col pb-4 rounded-3xl border-secondary border-2 py-2 px-2">
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-tertiary">
                        <thead>
                            <tr>
                                <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm"></th>
                                <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm">Full Name</th>
                                <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm">Phone Number</th>
                                <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm">Time</th>
                                <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm">Contacted</th>
                                <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm">Interested</th>
                                <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm">Converted</th>
                                <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm">Price</th>
                                <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm">Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leadsInfoData && Array.isArray(leadsInfoData) && leadsInfoData.length > 0 ? (
                                leadsInfoData.filter((item) => item.userName && item.userName.trim() !== "").slice(0, 8).map((item, index) => ( // Filters out items with no name
                                    <>
                                        <tr
                                            key={index}
                                            className="cursor-pointer"
                                        >
                                            <td className="py-1 px-2 md:py-2 md:px-4 border-b text-center text-xs md:text-sm font-normal md:font-semibold" onClick={() => {setShowLeadDetailsModal(true); setSelectedLead(item)}}>{index + 1}</td>
                                            <td className="py-1 px-2 md:py-2 md:px-4 border-b text-center text-xs md:text-sm font-normal md:font-semibold" onClick={() => {setShowLeadDetailsModal(true); setSelectedLead(item)}}>{item.userName}</td>
                                            <td className="py-1 px-2 md:py-2 md:px-4 border-b text-center text-xs md:text-sm font-normal md:font-semibold" onClick={() => {setShowLeadDetailsModal(true); setSelectedLead(item)}}>{item.phoneNumber}</td>
                                            <td className="py-1 px-2 md:py-2 border-b text-center text-xs md:text-sm font-normal md:font-semibold min-w-[35vw] md:min-w-[17vw] lg:min-w-[6vw]" onClick={() => {setShowLeadDetailsModal(true); setSelectedLead(item)}}>{timeFormat(item.timestamp)}</td>
                                            <td
                                                className="py-1 px-2 md:py-2 md:px-4 border-b text-center text-xs md:text-sm font-normal md:font-semibold cursor-pointer"
                                                onClick={() => {handleToggleContacted(item); setSelectedLead(item);}} // Pass both index and item
                                            >
                                                {item.contacted ? '✔️' : '-'}
                                            </td>
                                            <td
                                                className="py-2 px-4 border-b text-center text-sm font-normal md:font-semibold cursor-pointer"
                                                onClick={() => {handleToggleInterested(item); setSelectedLead(item);}}
                                            >
                                                {item.interested ? '✔️' : '-'}
                                            </td>
                                            <td
                                                className="py-2 px-4 border-b text-center text-sm font-normal md:font-semibold cursor-pointer"
                                                onClick={() => {setShowAddPriceModal(true); setSelectedLead(item)}}
                                            >
                                                {item.converted ? '✔️' : '-'}
                                            </td>
                                            <td className="py-2 px-4 border-b text-center text-sm font-normal md:font-semibold cursor-default">{item.membershipPrice ? item.membershipPrice : '-'}</td>
                                            <td
                                                className="py-2 px-4 border-b text-center text-sm font-normal md:font-semibold cursor-pointer"
                                                onClick={() => { 
                                                    setSelectedLead(item);          // Set the selected lead
                                                    setShowEditRemarksModal(true);  // Show the modal
                                                }}
                                            >
                                                {item.remarks}
                                            </td>
                                        </tr>
                                    </>
                                ))
                            // ) : leadsInfoData.length === null ? (
                            //     <tr className='h-[400px] text-4xl text-primary flex justify-between items-center'>
                            //         No Data Available
                            //     </tr>
                            ) : (
                                <tr className='h-[400px] flex justify-between items-center'>
                                    <LoadingSpinner />
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="h-0.5 bg-secondary rounded-full mt-1"></div>
                <div className="text-center mt-2 text-secondary font-semibold cursor-pointer hover:underline" onClick={() => setShowAllLeads(true)}>
                    See more
                </div>
            </div>

            {isModalOpen && <AddNewLeadModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSubmit={handleAddNewLead} />}
            {/* Edit Remarks Modal */}
            {showEditRemarksModal && selectedLead && (
                <EditRemarksModal lead={selectedLead} onClose={() => setShowEditRemarksModal(false)} onSubmit={handleRemarksUpdate} />
            )}

            {showAddPriceModal && selectedLead && (
                <AddPriceModal lead={selectedLead} onClose={() => setShowAddPriceModal(false)} onSubmit={handleAddPrice}/>
            )}

            {showLeadsDetailsModal && selectedLead && (
                <div className="fixed inset-y-0 left-0 z-30 bg-black bg-opacity-50 flex items-center">
                    <UserProfile currentPage="sales" member={selectedLead} onClose={() => setShowLeadDetailsModal(false)} basicGymData={{city: selectedLead.userCity, gymName: gymData.gymName}} gymData={gymData} setLeadsInfoData={setLeadsInfoData} setSubmitting={setSubmitting} setShowAddPriceModal={setShowAddPriceModal} setShowEditRemarksModal={setShowEditRemarksModal}/>
                </div>        
            )}
            

            {submitting && 
                <div className='z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'> 
                    <div className='h-44 w-44'>
                        <LoadingSpinner />
                    </div>
                </div>}
        </div>
    );
};

export default SalesPipeline;

export const AddNewLeadModal = ({ isOpen, onClose, onSubmit }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => onClose(), 500); // Delay close to match fade-out animation
    };

    if (!isOpen) return null;

    return (
        <div className={`z-40 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            <div className={`bg-secondary p-6 w-11/12 md:w-2/3 lg:w-1/2 h-[70vh] overflow-auto rounded-2xl transition-transform duration-500 transform ${isVisible ? 'scale-100' : 'scale-75'}`}>
                <h2 className="text-primary text-2xl md:text-4xl text-center font-bold mb-4">Add New Lead</h2>
                <form onSubmit={onSubmit}>
                    <div>
                        <label className="ml-2 md:ml-3 block mt-1 md:mt-2 text-sm md:text-lg text-primary font-semibold">User Name</label>
                        <input type="text" name="userName" required className="text-xs md:text-base border rounded w-full px-1 md:px-3 py-1 md:py-2" />
                    </div>
                    <div>
                        <label className="ml-2 md:ml-3 block mt-1 md:mt-2 text-sm md:text-lg text-primary font-semibold">Email</label>
                        <input type="email" name="email" required className="text-xs md:text-base border rounded w-full px-1 md:px-3 py-1 md:py-2" />
                    </div>
                    <div>
                        <label className="ml-2 md:ml-3 block mt-1 md:mt-2 text-sm md:text-lg text-primary font-semibold">Phone Number</label>
                        <input type="tel" name="phoneNumber" required className="text-xs md:text-base border rounded w-full px-1 md:px-3 py-1 md:py-2" />
                    </div>
                    <div>
                        <label className="ml-2 md:ml-3 block mt-1 md:mt-2 text-sm md:text-lg text-primary font-semibold">User City</label>
                        <input type="text" name="userCity" required className="text-xs md:text-base border rounded w-full px-1 md:px-3 py-1 md:py-2" />
                    </div>
                    <div>
                        <label className="ml-2 md:ml-3 block mt-1 md:mt-2 text-sm md:text-lg text-primary font-semibold">Remark</label>
                        <input type="text" name="remarks" className="text-xs md:text-base border rounded w-full px-1 md:px-3 py-1 md:py-2" />
                    </div>

                    <div className='flex flex-wrap gap-x-4 gap-y-2 mt-4'>
                        <div className="flex gap-1">
                            <label className="block text-sm md:text-lg text-primary font-semibold">Contacted</label>
                            <input type="checkbox" name="contacted" className="mr-2" />
                        </div>
                        <div className='flex gap-1 '>
                            <label className="block text-sm md:text-lg text-primary font-semibold">Interested</label>
                            <input type="checkbox" name="interested" className="mr-2" />
                        </div>
                        <div className='flex gap-1 '>
                            <label className="block text-sm md:text-lg text-primary font-semibold">Converted</label>
                            <input type="checkbox" name="converted" className="mr-2" />
                        </div>
                    </div>

                    <button type="submit" className="text-sm md:text-base mt-4 py-1 px-2 md:py-2 md:px-4 bg-tertiary text-primary rounded-md md:rounded-xl hover:scale-110 hover:shadow-xl hover:shadow-primary transition-all duration-300">Submit</button>
                    <button type="button" onClick={handleClose} className="ml-2 md:ml-4 lg:ml-10 text-sm md:text-base mt-4 py-1 px-2 md:py-2 md:px-4 bg-tertiary text-primary rounded-md md:rounded-xl hover:scale-110 hover:shadow-xl hover:shadow-primary transition-all duration-300">Cancel</button>
                </form>
            </div>
        </div>
    );
};

export const EditRemarksModal = ({ lead, onClose, onSubmit }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => onClose(), 500);
    };

    return (
        <div className={`z-40 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            <div className={`w-11/12 md:w-2/3 lg:w-1/3 p-6 bg-secondary rounded-2xl transition-transform duration-500 transform ${isVisible ? 'scale-100' : 'scale-75'}`}>
                <div className='text-primary text-2xl md:text-4xl text-left font-bold mb-2'>Edit Remark</div>
                <div className='text-primary text-sm md:text-lg text-left font-semibold mb-2'>Current Remark: <span className='text-tertiary text-base md:text-xl'>{lead.remarks}</span></div>
                <form onSubmit={(e) => onSubmit(e, lead)}>
                    <input type="text" name="remarks" required className="border rounded w-full px-2 py-1 md:px-3 md:py-2" />
                    <button type="submit" className="text-sm md:text-base mt-4 py-1 px-2 md:py-2 md:px-4 bg-tertiary text-primary rounded-md md:rounded-xl hover:scale-110 hover:shadow-xl hover:shadow-primary transition-all duration-300">Submit</button>
                    <button type="button" onClick={handleClose} className="ml-2 md:ml-4 lg:ml-10 text-sm md:text-base mt-4 py-1 px-2 md:py-2 md:px-4 bg-tertiary text-primary rounded-md md:rounded-xl hover:scale-110 hover:shadow-xl hover:shadow-primary transition-all duration-300">Cancel</button>
                </form>
            </div>
        </div>
    );
};

export const AddPriceModal = ({ lead, onClose, onSubmit }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => onClose(), 500);
    };

    return (
        <div className={`z-40 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            <div className={`p-6 bg-secondary rounded-2xl transition-transform w-11/12 md:w-1/2 lg:w-1/5 duration-500 transform ${isVisible ? 'scale-100' : 'scale-75'}`}>
                <div className='text-primary text-left font-bold mb-2'>
                    <div className='flex items-center gap-2 md:gap-4 mb-3 md:mb-6'>
                        <div className='text-2xl md:text-3xl lg:text-4xl'>Add Price</div>
                        <div className='flex justify-center items-center group text-tertiary text-xs md:text-sm h-4 w-4 md:h-6 md:w-6 lg:h-7 lg:w-7 relative rounded-full bg-primary cursor-pointer'>
                            ?
                            <span className='invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute bg-primary text-tertiary text-sm rounded-lg px-3 py-2 -top-16 left-8 w-40'>
                                The Price at which {lead.userName} bought the Gym Membership
                            </span>
                        </div>
                    </div>

                    <form onSubmit={(e) => onSubmit(e, lead)}>
                        <input type="text" name="price" required className="border rounded w-full py-1 px-2 md:px-3 md:py-2" />
                        <button type="submit" className="text-sm md:text-base mt-4 py-1 px-2 md:py-2 md:px-4 bg-tertiary text-primary rounded-md md:rounded-xl hover:scale-110 hover:shadow-xl hover:shadow-primary transition-all duration-300">Submit</button>
                        <button type="button" onClick={handleClose} className="ml-2 md:ml-4 lg:ml-10 text-sm md:text-base mt-4 py-1 px-2 md:py-2 md:px-4 bg-tertiary text-primary rounded-md md:rounded-xl hover:scale-110 hover:shadow-xl hover:shadow-primary transition-all duration-300">Cancel</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export const LeadDetailsModal = ({ lead, onClose }) => {
    const [isVisible, setIsVisible] = useState(false); // Control visibility state
    let formattedTime = timeFormat(lead?.timestamp);

    useEffect(() => {
        setIsVisible(true); // Start fade-in when modal mounts
    }, []);

    const handleClose = () => {
        setIsVisible(false); // Start fade-out
        setTimeout(() => {
            onClose(); // Close after the fade-out animation completes
        }, 500); // Delay must match the CSS transition duration
    };

    return (
        <div className={`z-40 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            <div className={`px-4 py-4 md:px-7 md:py-6 lg:px-10 lg:py-8 bg-secondary rounded-2xl w-3/4 lg:w-1/2 flex flex-col transition-transform duration-500 transform ${isVisible ? 'scale-100' : 'scale-75'}`}>
                <div className='grid grid-cols-2 md:grid-cols-3 gap-6 md:gap-14 lg:gap-20'>
                    <div className='flex flex-col gap-2 md:gap-4'>
                        <div className='font-semibold text-primary text-base md:text-xl lg:text-2xl'>Name</div>
                        <div className='text-tertiary text-xs md:text-base lg:text-lg'>{lead.userName}</div>
                    </div>
                    <div className='flex flex-col gap-2 md:gap-4'>
                        <div className='font-semibold text-primary text-base md:text-xl lg:text-2xl'>Email</div>
                        <div className='text-tertiary text-xs md:text-base lg:text-lg'>{lead.email}</div>
                    </div>
                    <div className='flex flex-col gap-2 md:gap-4'>
                        <div className='font-semibold text-primary text-base md:text-xl lg:text-2xl'>Phone Number</div>
                        <div className='text-tertiary text-xs md:text-base lg:text-lg'>{lead.phoneNumber}</div>
                    </div>
                    <div className='flex flex-col gap-2 md:gap-4'>
                        <div className='font-semibold text-primary text-base md:text-xl lg:text-2xl'>Contacted</div>
                        <div className='text-tertiary text-xs md:text-base lg:text-lg'>{lead.contacted ? "Yes" : "No"}</div>
                    </div>
                    <div className='flex flex-col gap-2 md:gap-4'>
                        <div className='font-semibold text-primary text-base md:text-xl lg:text-2xl'>Interested</div>
                        <div className='text-tertiary text-xs md:text-base lg:text-lg'>{lead.interested ? "Yes" : "No"}</div>
                    </div>
                    <div className='flex flex-col gap-2 md:gap-4'>
                        <div className='font-semibold text-primary text-base md:text-xl lg:text-2xl'>Converted</div>
                        <div className='text-tertiary text-xs md:text-base lg:text-lg'>{lead.converted ? "Yes" : "No"}</div>
                    </div>
                    <div className='flex flex-col gap-2 md:gap-4'>
                        <div className='font-semibold text-primary text-base md:text-xl lg:text-2xl'>Time</div>
                        <div className='text-tertiary text-xs md:text-base lg:text-lg'>{lead?.timestamp ? formattedTime : "-"}</div>
                    </div>
                    <div className='flex flex-col gap-2 md:gap-4'>
                        <div className='font-semibold text-primary text-base md:text-xl lg:text-2xl'>Price</div>
                        <div className='text-tertiary text-xs md:text-base lg:text-lg'>{lead.membershipPrice}</div>
                    </div>
                    <div className='flex flex-col gap-2 md:gap-4'>
                        <div className='font-semibold text-primary text-base md:text-xl lg:text-2xl'>Remarks</div>
                        <div className='text-tertiary text-xs md:text-base lg:text-lg'>{lead.remarks}</div>
                    </div>
                </div>

                <div className='w-full flex justify-end'>
                    <button 
                        type="button" 
                        onClick={handleClose} 
                        className="mr-8 mt-8 py-2 px-4 bg-tertiary text-primary rounded-xl hover:scale-110 hover:shadow-xl hover:shadow-primary transition-all duration-300"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}