import { addDoc, collection, doc, getDoc, setDoc } from "@firebase/firestore";
import { db } from "../config/firebase";
import { getDate, parseDate } from "./basicHelper";

/**
 * Stores data in 'user' database
 * @param {Object} myData - User data including city, gymName, months, userID
 * @param {Object} response - Response object from Razorpay containing paymentID, orderID, signature
 * @returns {boolean} - True if save successful, false otherwise
 */
export const storeUserData = async (myData, response) => {
    let startDate = myData.startDate || getDate();
    try {
        const myUserRef = doc(collection(db, 'user'), myData.user.uid);
        const userDoc = await getDoc(myUserRef);

        // If no such user with userID exists
        if (!userDoc.exists()) {
            // console.log('No User Found');
            return false;
        }

        // Add receipt to user -> {userID} -> gymMembershipReceipts collection
        const gymMembershipRef = collection(db, 'user', myData.user.uid, 'gymMembershipReceipts');
        await addDoc(gymMembershipRef, {
            timestamp       : new Date().toISOString(),
            paymentID       : response.razorpay_payment_id || "Self",
            orderType       : 'Gym Membership',
            months          : myData.months,
            gymName         : myData.gymName,
            city            : myData.city,
            amount          : myData.amount,
            startDate       : startDate,
            personalTrainer : myData.hasTrainer ? myData.trainerName : false, // If hasTrainer is True, store their name, else false
        });

        // Update user doc
        const userData = userDoc.data();
        // If membershipTill is in the past, then
        // the new membershipTill should be x months from today.
        await setDoc(myUserRef, {
            membershipTill: addMonthsToDate(
                parseDate(startDate) > parseDate(userData.membershipTill) ? parseDate(startDate) : parseDate(userData.membershipTill), 
                myData.months
            ),
            gymName: myData.gymName,
            gymCity: myData.city,
            // Add a gymMembershipID if necessary
        }, { merge: true });

        return true;

    } catch (e) {
        // console.log('Error:', e);
        return false;
    }
};


/**
 * Adds months to a given date
 * @param {string} dateStr - The date string in YYYY-MM-DD or DD-MM-YYYY format (or undefined)
 * @param {number} months - The number of months to add
 * @returns {string} - The new date string in DD-MM-YYYY format
 */
export const addMonthsToDate = (dateStr, months) => {
    let date;

    // console.log('AddMonthsToDate : ', dateStr);

    // Check if dateStr is undefined or not a valid date
    if (!dateStr || isNaN(Date.parse(convertToISOFormat(dateStr)))) {
        date = new Date(); // Use today's date if dateStr is undefined or invalid
    } else {
        date = new Date(convertToISOFormat(dateStr));
    }

    // Calculate the new month and year
    const newMonth = date.getMonth() + months;
    date.setMonth(newMonth);

    // Handle month overflow
    if (date.getMonth() !== (newMonth % 12)) {
        date.setDate(0);
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
};

/**
 * Converts a date string in DD-MM-YYYY format to YYYY-MM-DD format
 * @param {string} dateStr - The date string in DD-MM-YYYY format
 * @returns {string} - The date string in YYYY-MM-DD format
 */
const convertToISOFormat = (dateStr) => {
    const [day, month, year] = dateStr.split('-');
    return `${year}-${month}-${day}`;
};


/**
 * Converts a date string in 2024-09-30T06:37:33.209Z format to HH:MM DD-MM-YYYY format
 * @param {string} dateStr - The date string in DD-MM-YYYY format
 * @returns {string} - The date string in YYYY-MM-DD format
 */
export const timeFormat = (timestamp) => {
    const date = new Date(timestamp);

    // Extract the parts of the date and time
    const hours     = date.getUTCHours().toString().padStart(2, '0');
    const minutes   = date.getUTCMinutes().toString().padStart(2, '0');
    const day       = date.getUTCDate().toString().padStart(2, '0');
    const month     = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year      = date.getUTCFullYear();

    // Format the date as hh:mm dd-mm-yyyy
    const formattedDate = `${hours}:${minutes} ${day}-${month}-${year}`;
    return formattedDate
}

/**
 * Returns the current month in the format MM-YYYY
 * @returns {string} - The current month in MM-YYYY format
 */
export const getCurrentMonthAndYear = () => {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns 0-11
    const year = date.getFullYear();
    return `${month}-${year}`;
};

export const getMonthYearFromTimestamp = (timestamp) => {
    const date = new Date(timestamp); // Convert the timestamp to a Date object

    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (add 1 because months are 0-based) and pad with 0 if necessary
    const year = date.getFullYear(); // Get the year

    return `${month}-${year}`; // Return the formatted string
};


/**
 * Returns the previous month in the format MM-YYYY
 * @returns {string} - The previous month in MM-YYYY format
 */
export const getLastMonthAndYear = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);  // Move back by one month
    const month = String(date.getMonth() + 1).padStart(2, '0');  // Adjust for 0-indexed months
    const year = date.getFullYear();
    return `${month}-${year}`;
};

/**
 * Returns the last 6 months in the format MM-YYYY and their respective month names.
 * @returns {Array<Object>} - An array of objects containing the month name and MM-YYYY format.
 */
export const getLastSixMonthsAndYearWithNames = () => {
    const result = [];
    const currentDate = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    for (let i = 0; i < 6; i++) {
        const date = new Date();
        date.setMonth(currentDate.getMonth() - i); // Subtracting months from the current date
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adjust for 0-indexed months
        const year = date.getFullYear();
        result.push({
            name: monthNames[date.getMonth()], // Get the full month name
            monthYear: `${month}-${year}`
        });
    }

    return result; // Returns an array of the last 6 months with names
};