import React from 'react';

export const HtmlContent = () => {
    return (
        <div>
            twilio-domain-verification=e36d873b8742b5f0c72a63e8ee85988f
        </div>
    );
};

export default HtmlContent;