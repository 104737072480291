import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { fetchActiveUsers, fetchTrainers } from './PTHelper';
import { fetchWorkoutList } from './PTHelper';
import { handlePTSessionSubmit } from './PTHelper';

/**
 * PTSessionModal Component
 * A modal that allows assigning personal training sessions to users.
 * It enables selecting a user, trainer, and workout type, including custom workout options.
 * 
 * @param {boolean}     isOpen  - Determines if the modal is open.
 * @param {function}    onClose - Function to close the modal.
 * @param {Object}      gymData - Information of the gym
 */
const PTSessionModal = ({ isOpen, onClose, gymData }) => {
    // State for storing active users fetched from the database
    const [activeUsers, setActiveUsers]         = useState([]);
    // State for storing trainers fetched from the database
    const [trainers, setTrainers]               = useState([]);
    // State for storing the selected user from the dropdown
    const [selectedUser, setSelectedUser]       = useState(null);
    // State for storing the selected trainer from the dropdown
    const [selectedTrainer, setSelectedTrainer] = useState(null);
    // State for storing selected workout types from the multi-select dropdown
    const [userWorkouts, setUserWorkouts]       = useState([]);
    // State for storing custom workout input when 'other' is selected
    const [customWorkout, setCustomWorkout]     = useState('');

    // Workout options available for selection in the multi-select dropdown
    const workoutOptions = fetchWorkoutList();

    /**
     * Fetches active users and trainers when the modal is opened.
     * Utilizes helper functions to simulate fetching from a database.
     */
    useEffect(() => {
        if (isOpen) {
            fetchActiveUsers(gymData).then(users => setActiveUsers(users));
            fetchTrainers(gymData).then(trainers => setTrainers(trainers));
        }
    }, [isOpen]);

    /**
     * Handles the workout type selection from the multi-select dropdown.
     * Updates the selected workouts state.
     * 
     * @param {Array} selectedOptions - The selected workout options.
     */
    const handleWorkoutChange = (selectedOptions) => {
        const workoutLabels = selectedOptions ? selectedOptions.map(option => option.label) : [];
        setUserWorkouts(workoutLabels);
    };

    /**
     * Handles changes to the custom workout input field.
     * 
     * @param {Event} e - The change event from the input field.
     */
    const handleCustomWorkoutChange = (e) => {
        setCustomWorkout(e.target.value);
    };

    /**
     * Handles user selection from the searchable dropdown.
     * 
     * @param {Object} selectedOption - The selected user object.
     */
    const handleUserChange = (selectedOption) => {
        setSelectedUser(selectedOption);
    };

    /**
     * Handles trainer selection from the searchable dropdown.
     * 
     * @param {Object} selectedOption - The selected trainer object.
     */
    const handleTrainerChange = (selectedOption) => {
        setSelectedTrainer(selectedOption);
    };

    /**
     * Resets the form fields to their initial values.
     */
    const handleFormReset = () => {
        setSelectedUser(null);
        setSelectedTrainer(null);
        setUserWorkouts([]);
        setCustomWorkout('');
    };

    /**
     * Handles form submission by passing the form data to the handlePTSessionSubmit function.
     */
    const handleSubmit = () => {
        const sessionData = {
            userDocID           : selectedUser.userDocID,
            userName            : selectedUser.userName,
            userEmail           : selectedUser.email,
            userPhoneNumber     : selectedUser.phoneNumber,
            // Trainer Entries
            staffDocID          : selectedTrainer.staffDocID,
            staffName           : selectedTrainer.staffName,
            staffEmail          : selectedTrainer.email,
            staffPhoneNumber    : selectedTrainer.phoneNumber,
            // Additional Info
            workouts            : {
                ...userWorkouts,
                ...customWorkout,
            }
        };

        // Store the session
        if (handlePTSessionSubmit(gymData, sessionData)) {
            // Upon successfully storing the session, 
            // close the modal and reset the form
            onClose();
            handleFormReset();
        }
    };

    return (
        isOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                <div className="bg-secondary p-6 rounded-lg w-full max-w-3xl relative">
                    {/* 'x' button at the top-right to close the modal */}
                    <button
                        onClick={onClose}
                        className="absolute top-3 right-3 text-primary font-bold text-lg"
                    >
                        ×
                    </button>
                    <h2 className="text-lg font-bold mb-4 text-primary">Assign Personal Training Session</h2>
    
                    {/* User selection with search */}
                    <label className="block mb-2 text-primary">User Name:</label>
                    <Select
                        options={activeUsers.map(user => ({ value: user, label: user.userName }))}
                        value={selectedUser ? { value: selectedUser, label: selectedUser.userName } : null}
                        onChange={(selectedOption) => handleUserChange(selectedOption.value)}  // Pass the complete user object
                        isSearchable
                        placeholder="Select a user"
                        className="mb-4"
                        noOptionsMessage={() => 'No User available'}
                        styles={textBoxStyles}
                    />

                    {/* Trainer selection with search */}
                    <label className="block mb-2 text-primary">Trainer Name:</label>
                    <Select
                        options={trainers.map(trainer => ({ value: trainer, label: trainer.staffName }))}
                        value={selectedTrainer ? { value: selectedTrainer, label: selectedTrainer.staffName } : null}
                        onChange={(selectedOption) => handleTrainerChange(selectedOption.value)}  // Pass the complete trainer object
                        isSearchable
                        placeholder="Select a trainer"
                        className="mb-4"
                        noOptionsMessage={() => 'No Trainer available'}
                        styles={textBoxStyles}
                    />
                    {/* Workout selection with multi-select and custom workout option */}
                    <div className="mb-4">
                        <label className="block text-primary mb-2">Workout</label>
                        <Select
                            isMulti
                            options={workoutOptions}
                            value={userWorkouts.map(workout => ({ label: workout, value: workout }))}
                            onChange={handleWorkoutChange} // Map the selected options to store only labels
                            placeholder="Select Workout(s)"
                            className="basic-multi-select"
                            noOptionsMessage={() => 'No Workout available'}
                            styles={textBoxStyles}
                        />
                        {/* Custom workout input field */}
                        {userWorkouts.includes('Other') && (
                            <input
                                type="text"
                                value={customWorkout}
                                onChange={handleCustomWorkoutChange}
                                className="mt-2 w-full px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-primary focus:border-primary"
                                placeholder="Enter custom workout"
                            />
                        )}
                    </div>
    
                    {/* Action buttons */}
                    <div className="flex justify-between">
                        {/* Reset Button */}
                        <button
                            onClick={handleFormReset}
                            className="bg-tertiary hover:bg-primary hover:text-tertiary text-primary px-4 py-2 rounded"
                        >
                            Reset
                        </button>
    
                        {/* Submit Button */}
                        <button
                            onClick={handleSubmit}
                            className="bg-tertiary hover:bg-primary hover:text-tertiary text-primary px-4 py-2 rounded"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        )
    );    
};

export default PTSessionModal;


const textBoxStyles = {
    control: (provided) => ({
        ...provided,
        borderColor: 'var(--primary)',
        boxShadow: 'none', // Remove default focus shadow
        '&:hover': {
            borderColor: 'var(--primary)',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'var(--secondary)' : 'white',
        color: 'black',
        '&:hover': {
            backgroundColor: 'var(--tertiary)',
            color: 'black',
        },
    }),
}