import React, { useState, useEffect } from 'react';
import { storeCity, storeGym } from '../../helperFunctions/GymFormHelper';
import SuccessModal from '../../helperFunctions/Modal';
import 'react-phone-number-input/style.css'
import { useLocation } from 'react-router-dom';
import { capitalizeFirstLetter, formatWordToLowerCase } from '../../helperFunctions/basicHelper';
import TR_Logo from "../../assets/Website_content/TR_Website_content-02.svg"
import { Commission, EquipmentSelection, Exclusive, FirstSection, ReviewCommissionPrices, ReviewExclusivePrices, SlideOne, SlideTwo } from '../../helperFunctions/GymFormSections';
import LoadingSpinner from '../../components/LoadingSpinner';

/**
 * Function to generate a random UUID
 * @returns A random ID
 */
function generateRandomUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 * Function to generate a random email address
 * @returns A random Email address with domain - @trainrex.org
 */
function generateRandomEmail() {
    const domains = ['trainrex.org'];
    const username = Math.random().toString(36).substring(7);
    const domain = domains[Math.floor(Math.random() * domains.length)];
    return `${username}@${domain}`;
}

/**
 * Function to generate a random UK phone number
 * @returns A random UK phone number
 */
function generateRandomUKPhoneNumber() {
    const leadingDigits = ['74', '75', '76', '77', '78', '79'];
    const randomLeadingDigit = leadingDigits[Math.floor(Math.random() * leadingDigits.length)];
    const randomDigits = Math.random().toString().slice(2, 10);
    return `+44 ${randomLeadingDigit} ${randomDigits.slice(0, 3)} ${randomDigits.slice(3)}`;
}


/*
 * This page gets the details of the gym to enter data to the main database
 * This is used to on-board gym on the TrainRex Wesbite and App
 * @param   :   db          -   database management tool
 * @return  :   form data   -   Gym Name
 *                              Gym Locality
 *                              City
 *                              Full Name of the Owner
 */
const GymForm = () => {
   
    // Get Props from GymCred.js
    const location = useLocation();
    const { user } = location.state || {};  //  Here user is gym
   
    /*
     * -------------------------------------------
     * ------------ THIS BLOCK OF CODE -----------
     * --------- GENERATES A RANDOM USER ---------
     * -------------------------------------------
     */

    // const [user, setUser] = useState({
    //     uid: '',
    //     email: '',
    //     phoneNumber: ''
    // });

    // useEffect(() => {
    //     setUser({
    //         uid         : generateRandomUUID(),
    //         email       : generateRandomEmail(),
    //         phoneNumber : generateRandomUKPhoneNumber()
    //     });
    // }, []); // Empty dependency array ensures this effect runs only once

    /* --------- RANDOM USER BLOCK ENDS ---------- */

    const [formData, setFormData] = useState({
        city                : '',
        gym                 : '',
        locality            : '',
        owner               : '',
        phoneNumber         : user.phoneNumber,
        gymContactNo        : '',
        address             : '',
        openingTime         : '',
        closingTime         : '',
        timeSlots           : [{ openingTime: '', closingTime: '' }],
        gymLogo             : '',
        Portfolio           : [],
        email               : user.email,
        gstin               : '',
        country             : '',
        pinCode             : '',

        // Pricing Structure (Single, Couple, Family)
        paymentModel        : '',

        prices              : {
            single              : {
                dailyPrice              : '',
                monthlyPrice            : '',
                quarterlyPrice          : '',
                halfYearlyPrice         : '',
                yearlyPrice             : '',

                exclusiveDailyPrice     : '',  
                exclusiveMonthlyPrice   : '',
                exclusiveQuarterlyPrice : '',
                exclusiveHalfYearlyPrice: '',
                exclusiveYearlyPrice    : '',   
            },
            couple              : {
                dailyPrice              : '',
                monthlyPrice            : '',
                quarterlyPrice          : '',
                halfYearlyPrice         : '',
                yearlyPrice             : '',

                exclusiveDailyPrice     : '',  
                exclusiveMonthlyPrice   : '',
                exclusiveQuarterlyPrice : '',
                exclusiveHalfYearlyPrice: '',
                exclusiveYearlyPrice    : '',   
            }, 
            family              : {
                dailyPrice              : '',
                monthlyPrice            : '',
                quarterlyPrice          : '',
                halfYearlyPrice         : '',
                yearlyPrice             : '',

                exclusiveDailyPrice     : '',  
                exclusiveMonthlyPrice   : '',
                exclusiveQuarterlyPrice : '',
                exclusiveHalfYearlyPrice: '',
                exclusiveYearlyPrice    : '',   
            },
        },

        // Equipment List
        equipmentList       : {
            AbductionAndAdductionMachine    : false,
            AdductorMachine                 : false,
            ArmWrestlingPlatform            : false,
            BackrollMachine                 : false,
            BattleRope                      : false,
            CableCrossover                  : false,
            CableRowMachine                 : false,
            CalfMachine                     : false,
            ChestPress                      : false,
            DeclineBech                     : false,
            DeclinePress                    : false,
            Dumbbells                       : false,
            FlatBench                       : false,
            HackSquatMachine                : false,
            HyperExtension                  : false,
            InclineBench                    : false,
            InfiniteMachine                 : false,
            LatPullDown                     : false,
            LegCurlMachine                  : false,
            LegExtension                    : false,
            LegPress                        : false,
            PeckDeckFly                     : false,
            PreacherCurlMachine             : false,
            PullUpBar                       : false,
            RowingMachine                   : false,
            ShoulderPress                   : false,
            SmithMachine                    : false,
            SquatBar                        : false,
            StationaryBicycle               : false,
            Treadmill                       : false,
            Tyre                            : false,
            WallClimbing                    : false
        },

        // Facilities
        facilities          : {
            Spa             : false,
            Sauna           : false,
            CardioArea      : false,
            StrengthArea    : false,
            LoungeAndCafe   : false,
            BoxingRing      : false,
            MMA             : false,
            ValetParking    : false,
            WallClimbing    : false,
        },

        // Classes
        classes             : {
            Zumba               : false,
            HIIT                : false,
            FunctionalTraining  : false,
            CoreClasses         : false,
            KickBoxing          : false,
            Aerobics            : false,
            Pilates             : false,
        },
    });

    const [currentSection, setCurrentSection] = useState(1);
    const [newEquipment, setNewEquipment] = useState("");
    const [newFacilities, setNewFacilities] = useState("");
    const [newClasses, setNewClasses] = useState("");
    const [imgURL, setImgURL] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [pricingType, setPricingType] = useState('');
    const [currentSlide, setCurrentSlide] = useState(1);
    const [selectedOffers, setSelectedOffers] = useState({
        single: false,
        couple: false,
        family: false,
    });

    console.log("Current Slide : ", currentSlide)
    
    // Handle pricing type change
    const handlePricingTypeChange = (e) => {
        setPricingType(e.target.value);
    };

    // Handle offers checkbox toggle
    const handleOfferChange = (offer) => {
        setSelectedOffers((prevState) => ({
            ...prevState,
            [offer]: !prevState[offer],
        }));
    };
   
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handlePriceChange = (e) => {
        const { name, value } = e.target;
        
        // Use a dynamic approach to update nested object in formData
        setFormData((prevFormData) => {
            // Split the name to access nested structure (e.g., 'prices.single.dailyPrice')
            const keys = name.split('.');  // ['prices', 'single', 'dailyPrice']
    
            // Clone the current formData to avoid direct mutation
            const updatedFormData = { ...prevFormData };
    
            // Create a reference to the deepest object inside formData
            let currentLevel = updatedFormData;
    
            // Iterate through the keys, except the last one (which holds the value)
            for (let i = 0; i < keys.length - 1; i++) {
                const key = keys[i];
                currentLevel[key] = { ...currentLevel[key] };  // Clone each level to avoid mutation
                currentLevel = currentLevel[key];
            }
    
            // Assign the new value to the last key (e.g., 'dailyPrice')
            currentLevel[keys[keys.length - 1]] = value;
    
            return updatedFormData;
        });
    };    

    

    const handleAddTimeSlot = () => {
        setFormData(prevData => ({
            ...prevData,
            timeSlots: [...prevData.timeSlots, { openingTime: '', closingTime: '' }],
        }));
    };
    
    const handleRemoveTimeSlot = index => {
        setFormData(prevData => ({
            ...prevData,
            timeSlots: prevData.timeSlots.filter((_, i) => i !== index),
        }));
    };
    
    const handleTimeSlotChange = (index, field, value) => {
        setFormData(prevData => {
            const newTimeSlots = [...prevData.timeSlots];
            newTimeSlots[index] = { ...newTimeSlots[index], [field]: value };
            return { ...prevData, timeSlots: newTimeSlots };
        });
    };

    // For multiple images upload at once
    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to an array
    
        // This array will store the base64-encoded images
        const updatedImages = [];
    
        files.forEach((file) => {
            const reader = new FileReader();
            
            reader.onloadend = () => {
                const img = new Image();
                img.src = reader.result;
        
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    let width = img.width;
                    let height = img.height;
    
                    const maxWidth = 800;  // Define max width for resizing
                    const maxHeight = 600; // Define max height for resizing
                    const quality = 0.9;   // Define quality for compression (0.0 to 1.0)
        
                    // Resize the image based on max width and height
                    if (width > height) {
                        if (width > maxWidth) {
                            height = Math.round((height * maxWidth) / width);
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width = Math.round((width * maxHeight) / height);
                            height = maxHeight;
                        }
                    }
        
                    // Set the canvas dimensions
                    canvas.width = width;
                    canvas.height = height;
        
                    // Draw the image onto the canvas
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
        
                    // Convert the canvas to a Base64 string with the specified quality
                    const dataUrl = canvas.toDataURL('image/jpeg', quality);
    
                    // Push the base64 image string into the updatedImages array
                    updatedImages.push(dataUrl);
    
                    // Once all images are processed, update the state
                    if (updatedImages.length === files.length) {
                        setFormData((prevFormData) => ({
                            ...prevFormData,
                            Portfolio: updatedImages,
                        }));
                    }
                };
            };
            
            reader.readAsDataURL(file);
        });
    };    


    // this will convert the image to base64string and this string will be stored in the firebase database and can be fetched along with all the data on the dashboard to display gymLogo
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
    
        const maxWidth = 600;  // Define max width for resizing
        const maxHeight = 600; // Define max height for resizing
        const quality = 0.7;   // Define quality for compression (0.0 to 1.0)
    
        const reader = new FileReader();
        reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result;
    
            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;
    
                // Resize the image based on max width and height
                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height * maxWidth) / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width * maxHeight) / height);
                        height = maxHeight;
                    }
                }
    
                // Set the canvas dimensions
                canvas.width = width;
                canvas.height = height;
    
                // Draw the image onto the canvas
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
    
                // Convert the canvas to a Base64 string with the specified quality
                const dataUrl = canvas.toDataURL('image/jpeg', quality);
    
                // Set the resized and compressed image as the state
                setImgURL(dataUrl);
            };
        };
    
        reader.readAsDataURL(file);
    };
   
    useEffect(() => {
        if (imgURL) {
            // Update the formData with the imgURL when imgURL changes
            console.log("Img URL : ", imgURL);
            setFormData((prevFormData) => ({ ...prevFormData, gymLogo: imgURL}));
        }
    }, [imgURL]);

    const goToNextSection = () => {
        setCurrentSection(prevSection => prevSection + 1);
    };

    const goToPreviousSection = () => {
        setCurrentSection(prevSection => prevSection - 1);
    };

    /*
     * Submits the data, and
     * Stores the data in the database
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        setSubmitting(true);
    
        // Preprocessing Data
        const info = {
            city              : formatWordToLowerCase(formData.city),
            gymName           : formatWordToLowerCase(formData.gym),
            gymDisplayName    : capitalizeFirstLetter(formData.gym),
            locality          : capitalizeFirstLetter(formData.locality),
            phoneNumber       : formData.phoneNumber,
            gymContactNo      : `+91${formData.gymContactNo}`,
            owner             : formData.owner,
            address           : capitalizeFirstLetter(formData.address),
            gymLogo           : formData.gymLogo,
            email             : user.email,
            gstin             : formData.gstin,
            country           : capitalizeFirstLetter(formData.country),
            pinCode           : formData.pinCode,
    
            // Include time slots in the info object
            timeSlots         : formData.timeSlots.map(slot => ({
                openingTime: slot.openingTime,
                closingTime: slot.closingTime,
            })),
            
            // Pricing Structure (Single, Couple, Family)
            paymentModel      : pricingType,
            prices            : formData.prices,
    
            cityDisplayName   : capitalizeFirstLetter(formData.city),
            equipmentList     : formData.equipmentList,
            facilities        : formData.facilities,
            classes           : formData.classes
        };
    
        // Storing City
        if (await storeCity(info.city)) {
            console.log('City Added!');
        } else {
            console.log('Failed to add city');
            setSubmitting(false);
            return false; // Exit form on failure
        }
    
        // Storing Gym
        if (await storeGym(info, user)) {
            console.log('Gym Added!');
        } else {
            console.log('Failed to add Gym!');
            setSubmitting(false);
            return false;
        }
    
        // Clear form data after successful submission
        setFormData({
            city                : '',
            gym                 : '',
            locality            : '',
            owner               : '',
            phoneNumber         : '',
            gymContactNo        : '',
            address             : '',
            timeSlots           : [{ openingTime: '', closingTime: '' }], // Reset time slots
    
            gymLogo             : '',
            email               : '',
            gstin               : '',
            country             : '',
            pinCode             : '',
    
            paymentModel        : '',
    
            prices              : {
                single              : {
                    dailyPrice              : '',
                    monthlyPrice            : '',
                    quarterlyPrice          : '',
                    halfYearlyPrice         : '',
                    yearlyPrice             : '',
    
                    exclusiveDailyPrice     : '',  
                    exclusiveMonthlyPrice   : '',
                    exclusiveQuarterlyPrice : '',
                    exclusiveHalfYearlyPrice: '',
                    exclusiveYearlyPrice    : '',   
                },
                couple              : {
                    dailyPrice              : '',
                    monthlyPrice            : '',
                    quarterlyPrice          : '',
                    halfYearlyPrice         : '',
                    yearlyPrice             : '',
    
                    exclusiveDailyPrice     : '',  
                    exclusiveMonthlyPrice   : '',
                    exclusiveQuarterlyPrice : '',
                    exclusiveHalfYearlyPrice: '',
                    exclusiveYearlyPrice    : '',   
                }, 
                family              : {
                    dailyPrice              : '',
                    monthlyPrice            : '',
                    quarterlyPrice          : '',
                    halfYearlyPrice         : '',
                    yearlyPrice             : '',
    
                    exclusiveDailyPrice     : '',  
                    exclusiveMonthlyPrice   : '',
                    exclusiveQuarterlyPrice : '',
                    exclusiveHalfYearlyPrice: '',
                    exclusiveYearlyPrice    : '',   
                },
            },
    
            // Reset equipmentList
            equipmentList       : {
                AbductionAndAdductionMachine    : false,
                AdductorMachine                 : false,
                ArmWrestlingPlatform            : false,
                BackrollMachine                 : false,
                BattleRope                      : false,
                CableCrossover                  : false,
                CableRowMachine                 : false,
                CalfMachine                     : false,
                ChestPress                      : false,
                DeclineBech                     : false,
                DeclinePress                    : false,
                Dumbbells                       : false,
                FlatBench                       : false,
                HackSquatMachine                : false,
                HyperExtension                  : false,
                InclineBench                    : false,
                InfiniteMachine                 : false,
                LatPullDown                     : false,
                LegCurlMachine                  : false,
                LegExtension                    : false,
                LegPress                        : false,
                PeckDeckFly                     : false,
                PreacherCurlMachine             : false,
                PullUpBar                       : false,
                RowingMachine                   : false,
                ShoulderPress                   : false,
                SmithMachine                    : false,
                SquatBar                        : false,
                StationaryBicycle               : false,
                Treadmill                       : false,
                Tyre                            : false,
                WallClimbing                    : false
            },
    
            // Reset facilities
            facilities          : {
                Spa             : false,
                Sauna           : false,
                CardioArea      : false,
                StrengthArea    : false,
                LoungeAndCafe   : false,
                BoxingRing      : false,
                MMA             : false,
                ValetParking    : false,
                WallClimbing    : false,
            },
    
            // Reset classes
            classes             : {
                Zumba               : false,
                HIIT                : false,
                FunctionalTraining  : false,
                CoreClasses         : false,
                KickBoxing          : false,
                Aerobics            : false,
                Pilates             : false,
            },
        });
    
        // Display Alert Message
        setModalIsOpen(true);
        setSubmitting(false); // Reset submitting state
    
        return true;
    };

    return (
        <div className="flex flex-col bg-tertiary px-6 lg:px-56 py-14">
            <div className='flex justify-between items-center mb-14'>
                <img src={TR_Logo} alt="Train Rex Logo" className='h-10 object-cover' />
                <h1 className="flex text-left text-xl lg:text-6xl font-bold ">Gym Details</h1>
            </div>
            
            <form onSubmit={handleSubmit}>

                {/* Section 1: Basic Information */}
                {currentSection === 1 && (
                    <FirstSection formData={formData} user={user} handleChange={handleChange} goToNextSection={goToNextSection} handleImageUpload={handleImageUpload} handleImageChange={handleImageChange} handleAddTimeSlot={handleAddTimeSlot} handleRemoveTimeSlot={handleRemoveTimeSlot} handleTimeSlotChange={handleTimeSlotChange}/>
                )}


                {/* Section 2: Owner Information */}
                {currentSection === 2 && (
                    <>
                        <div className="mb-8">
                            <h2 className="flex text-left text-xl lg:text-2xl font-semibold mb-4">Owner Information</h2>

                            {/* Textbox 11 */}
                            <div className="mb-4 flex items-center">
                                <label className="flex text-left w-1/4 text-sm lg:text-xl font-medium">
                                    Owner's Name
                                </label>
                                <input
                                    type="text"
                                    name="owner"
                                    value={formData.owner}
                                    onChange={handleChange}
                                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                                />
                            </div>
                        </div>

                        {/* Section 3: Choose Payment Model */}
                        <div className="mb-4">
                            <h2 className="flex text-left text-xl lg:text-2xl font-semibold mb-4">
                                Payment Model
                            </h2>

                            {/* Pricing Type Selector */}
                            <div className="relative mb-4 flex justify-center gap-20 rounded-3xl border-4 border-secondary w-[76vw] h-[35vh]">
                                {/* Slide 1: Pricing Type Selector */}
                                {currentSlide === 1 && (
                                    <SlideOne selectedOffers={selectedOffers} pricingType={pricingType} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} handlePricingTypeChange={handlePricingTypeChange}/>
                                )}

                                {/* Slide 2: Offers */}
                                {currentSlide === 2 && (
                                    <SlideTwo selectedOffers={selectedOffers} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} pricingType={pricingType} handleOfferChange={handleOfferChange}/>
                                )}
                                
                                {/* Pricing Details Ahead */}
                                {currentSlide === 3 && pricingType === "commission" && (
                                    <Commission userType="single" formData={formData} handlePriceChange={handlePriceChange} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} selectedOffers={selectedOffers}/>
                                )}

                                {currentSlide === 3 && pricingType === "exclusive"  && (
                                    <Exclusive  userType="single" formData={formData} handlePriceChange={handlePriceChange} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} selectedOffers={selectedOffers}/>
                                )}

                                {currentSlide === 4 && pricingType === "commission" && (
                                    <Commission userType="couple" formData={formData} handlePriceChange={handlePriceChange} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} selectedOffers={selectedOffers}/>
                                )}

                                {currentSlide === 4 && pricingType === "exclusive"  && (
                                    <Exclusive  userType="couple" formData={formData} handlePriceChange={handlePriceChange} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} selectedOffers={selectedOffers}/>
                                )}

                                {currentSlide === 5 && pricingType === "commission" && (
                                    <Commission userType="family" formData={formData} handlePriceChange={handlePriceChange} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} selectedOffers={selectedOffers}/>
                                )}

                                {currentSlide === 5 && pricingType === "exclusive"  && (
                                    <Exclusive  userType="family" formData={formData} handlePriceChange={handlePriceChange} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} selectedOffers={selectedOffers}/>
                                )}

                                {currentSlide === 6 && pricingType === "commission" && (
                                    <ReviewCommissionPrices formData={formData} selectedOffers={selectedOffers} setCurrentSlide={setCurrentSlide}/>
                                )}

                                {currentSlide === 6 && pricingType === "exclusive" && (
                                    <ReviewExclusivePrices  formData={formData} selectedOffers={selectedOffers} setCurrentSlide={setCurrentSlide}/>
                                )}
                            </div>

                            {/* Navigation buttons */}
                            <div className="flex justify-center gap-10 mt-20 ">
                                <button onClick={goToPreviousSection} className="px-4 py-2 bg-secondary w-28 rounded-full text-tertiary hover:bg-primary hover:scale-110 transition-all duration-300">
                                    &lt; Back
                                </button>
                                <button 
                                    onClick={goToNextSection} 
                                    disabled={currentSlide !== 6}
                                    className={`px-4 py-2 bg-secondary w-28 rounded-full text-tertiary hover:bg-primary hover:scale-110 transition-all duration-300 ${currentSlide !== 6 ? 'opacity-50 cursor-default' : ''}`}
                                >
                                    Next &gt;
                                </button>
                            </div>
                        </div>
                    </>
                )}    


                {currentSection === 3 && (
                    <EquipmentSelection formData={formData} setFormData={setFormData} submitting={submitting} goToPreviousSection={goToPreviousSection} newClasses={newClasses} setNewClasses={setNewClasses} newFacilities={newFacilities} setNewFacilities={setNewFacilities} newEquipment={newEquipment} setNewEquipment={setNewEquipment}/>
                )}
            </form>

            {submitting && 
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <div className="h-40 w-40">
                    <LoadingSpinner />
                    </div>
                </div>
            }

            <SuccessModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
        </div>
    );
};

export default GymForm;