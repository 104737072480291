import React, { useState, useEffect } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, createUserWithEmailAndPassword, linkWithCredential, updateProfile } from 'firebase/auth';
import { authGym, db } from '../../config/firebase';
import { doc, setDoc } from '@firebase/firestore';
import { gymList } from '../../components/Databases';
import { useNavigate } from 'react-router-dom';


function GymCred() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationId, setVerificationId] = useState('');
    const [credential, setCredential] = useState(null);
    const [showEmailPasswordForm, setShowEmailPasswordForm] = useState(false);
    const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(false);
    const [countryCode, setCountryCode] = useState('+91');

    const navigate = useNavigate();

    /**
     * Initialises Recaptcha upon page loading
     * Used to filter out bots
     */
    useEffect(() => {
        const initializeRecaptcha = async () => {
        if (!window.recaptchaVerifier) {
            try {
            window.recaptchaVerifier = new RecaptchaVerifier(authGym, 'recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                // console.log("reCAPTCHA solved:", response);
                },
                'expired-callback': () => {
                // console.log("reCAPTCHA expired");
                }
            });
            await window.recaptchaVerifier.render();
            } catch (error) {
            // console.error("Error initializing reCAPTCHA", error);
            }
        }
        };
        initializeRecaptcha();
    }, []);

    /**
     * Handles the submission of phone Number
     * Checks if recaptcha is verified, if not, returns
     * If yes, authenticates user with their phone number
     * @param {String} phoneNumber 
     * @param {RecaptchaVerifier} appVerifier
     */
    const handlePhoneNumberSubmit = async () => {
        const fullPhoneNumber = `${countryCode}${phone}`;
        const appVerifier = window.recaptchaVerifier;
    
        if (!window.recaptchaVerifier) {
          console.error("reCAPTCHA verifier is not initialized");
          return;
        }
    
        try {
            const confirmationResult = await signInWithPhoneNumber(authGym, fullPhoneNumber, appVerifier);
            setVerificationId(confirmationResult.verificationId);
            setShowVerificationCodeInput(true);
        } catch (error) {
            // console.error("Error during phone number verification", error.message);
            // console.error(error);
        }
    };

    /**
     * Once the verificstion code is sent
     * This fn, verifies the code
     * Changes the form layout -> Email and Password textboxes are now visible
     */
    const handleCodeVerification = async () => {
        try {
        const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
        setCredential(cred);
        setShowEmailPasswordForm(true);
        } catch (error) {
        // console.error("Error verifying code", error);
        }
    };

    /**
     * Adds user to the database
     * @param {User} user 
     * @returns {boolean}
     */
    const addUserToDoc = async(user) => {
        try {
        const userRef = doc(db, gymList, user.uid);
        await setDoc(userRef, {
            phoneNumber :   phone,
            email       :   email,
        });
        return true;
        } catch (error) {
        // console.error("Error adding user to Firestore", error);
        return false;
        }
    };

    /**
     * Checks if both passwords are equal
     * Calls addUserToDoc
     * Links User credentials
     * Navigates to /GymForm
     * @param {*} e 
     * @returns 
     */
    const handleSignUp = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
        alert('Passwords do not match.');
        return;
        }

        try {
        const userCredential = await createUserWithEmailAndPassword(authGym, email, password);
        const user = userCredential.user;

        if (await addUserToDoc(user)) {
            // await updateProfile(user, { displayName: name });
            await linkWithCredential(user, credential);
            // console.log("User signed up and phone number linked:", user);
            
            // If everything is successful, navigate to GymForm page with user data
            try {
                // Extracting necessary data from user object
                const userData = {
                    uid: user.uid,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                };
                // console.log('Nagivating to GymForm, with data : ', userData);
                navigate('/GymForm', { state: { user: userData } });
            } catch (error) {
                // console.log('Error : ', error);
            }
        }
        } catch (error) {
            // console.error("Error during sign up", error);
            alert("Error during sign up:", error.message);
            
            // In case of error, navigate to homepage
            navigate('/');
        }
    };

    
    return (
        <div className="min-h-screen flex items-center justify-center bg-tertiary">
            <div className="max-w-md w-full bg-secondary rounded-lg shadow-md overflow-hidden">
                <div className="p-6 sm:p-8">
                    <h2 className="text-2xl font-bold text-center text-primary">Register Your Gym</h2>
                    {!showEmailPasswordForm ? (
                        <div>
                            {!showVerificationCodeInput ? (
                                <div className="mt-8 space-y-6">
                                    <div className="rounded-md shadow-sm -space-y-px">
                                        <div>
                                            <label htmlFor="phone" className="sr-only">Phone number</label>
                                            <input
                                                id="phone"
                                                name="phone"
                                                type="tel"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                required
                                                className="appearance-none bg-white rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-800 text-black rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-md"
                                                placeholder="Phone number"
                                            />
                                        </div>
                                    </div>
                                    <button
                                        onClick={handlePhoneNumberSubmit}
                                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-xl text-primary bg-tertiary hover:bg-primary hover:text-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:black"
                                    >
                                        Send Verification Code
                                    </button>
                                    <div id="recaptcha-container"></div>
                                </div>
                            ) : (
                                <div className="mt-8 space-y-6">
                                    <div className="text-center text-primary mb-4">
                                        <span className="font-bold block">Verification Code has been sent to :</span>
                                        <span className="font-bold block">{countryCode} - {phone}</span>
                                    </div>
                                    <div>
                                        <label htmlFor="verificationCode" className="sr-only">Verification Code</label>
                                        <input
                                            id="verificationCode"
                                            name="verificationCode"
                                            type="text"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                            required
                                            className="appearance-none bg-white rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-800 text-black focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-md"
                                            placeholder="Enter verification code"
                                        />
                                    </div>
                                    <button
                                        onClick={handleCodeVerification}
                                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-xl text-primary bg-tertiary hover:bg-primary hover:text-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Verify Code
                                    </button>
                                    {/* <div id="recaptcha-container"></div> */}
                                </div>
                            )}
                        </div>
                    ) : (
                        <form className="mt-8 space-y-6" onSubmit={handleSignUp}>
                            <div className="rounded-md shadow-sm space-y-4">
                                {/* Email Address */}
                                <div className="mt-4">
                                    <label htmlFor="email" className="sr-only">Email address</label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        className="appearance-none rounded-md relative block w-full px-3 py-2 border border-primary placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-md"
                                        placeholder="Email address"
                                    />
                                </div>

                                {/* Password */}
                                <div className="mt-4">
                                    <label htmlFor="password" className="sr-only">Password</label>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        className="appearance-none rounded-md relative block w-full px-3 py-2 border border-primary placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-md"
                                        placeholder="Password"
                                    />
                                </div>

                                {/* Confirm Password */}
                                <div className="mt-4">
                                    <label htmlFor="confirm-password" className="sr-only">Confirm Password</label>
                                    <input
                                        id="confirm-password"
                                        name="confirm-password"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                        className="appearance-none rounded-md relative block w-full px-3 py-2 border border-primary placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-md"
                                        placeholder="Confirm Password"
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-none text-md font-medium rounded-md text-primary bg-tertiary hover:bg-primary hover:text-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black shadow-md"
                                >
                                Sign Up
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
}

export default GymCred;
