import { getUserCountryCode } from "./userLocationHelpers";

/**
 * @return  :   current time
 */
export const getTime = () => {
    const now = new Date();

    const isoString = now.toISOString();
    return isoString.slice(0, -1) + 'Z'; // Remove the last character (Z) and append it again to ensure consistent format;
}

/**
 * Returns today's date
 * @format  : YYYY-MM-DD
 * @return  : Today's Date
 */
export const getDate2 = () => {
    const now = new Date();

    // Get Today's Date 
    return now.toISOString().split('T')[0];  // Outputs: '2024-05-22'
}

/**
 * Returns a Date object for today's date in DD-MM-YYYY format with the current time
 * @return {Date} - Date object
 */
export const getDate3 = () => {
    const now = new Date();

    // Extract the current date components using toLocaleString
    const day = String(now.toLocaleString('en-GB', { day: '2-digit' }));
    const month = String(now.toLocaleString('en-GB', { month: '2-digit' }));
    const year = now.toLocaleString('en-GB', { year: 'numeric' });

    // Extract current time components using native methods
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const milliseconds = now.getMilliseconds();

    // Create a Date object with the current date and time
    const timestamp = new Date(year, parseInt(month) - 1, parseInt(day), hours, minutes, seconds, milliseconds);

    return timestamp; // Returns Date object with the current date and time
};

/**
 * Returns today's date
 * @format  : DD-MM-YYYY
 * @return  : Today's Date
 */
export const getDate = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = now.getFullYear();

    return `${day}-${month}-${year}`;  // Outputs: '22-05-2024'
}

/**
 * Returns today's date
 * @format  : DD-MM-YYYY
 * @return  : Today's Date
 */
export const getDateYYYYMMDD = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = now.getFullYear();

    return `${year}-${month}-${day}`;  // Outputs: '22-05-2024'
}



/**
 * Gets Year Month and Date individually
 * @param   : void
 * @return  : Year, Month and Date
 */
export const getCalendar = () => {
    const now = new Date();
    
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const date = String(now.getDate()).padStart(2, '0');

    // Combine year, month, and date
    return {year, month, date};
}

/**
 * Returns the current Year
 * @param {void}
 * @returns {String} - Current Year
 */
export const getCurrentYear = () => {
    const currentYear = new Date().getFullYear();
    return currentYear.toString();
}

/**
 * Gets the current month in a string format (e.g., "August").
 * @returns {string} The current month.
 */
export const getCurrentMonth = () => {
    const months = [
        "January", "February", "March", "April", "May", "June", 
        "July", "August", "September", "October", "November", "December"
    ];
    const currentMonthIndex = new Date().getMonth();  // getMonth() returns 0-11
    return months[currentMonthIndex];
};

/**
 * Refines the Data according to the database
 */
export const refineData = (myData) => {
    const refinedData = { ...myData }; // Create a shallow copy of myData

    if (myData.city) {
        refinedData.city = myData.city.toLowerCase().replace(/\s+/g, '');
    }
    if (myData.gymName) {
        refinedData.gymName = myData.gymName.toLowerCase().replace(/\s+/g, '');
    }
    return refinedData;
};

/**
 * Capitalises first letter of every word
 * @param {String} word
 * @returns {String} Capitalised Word
 */
export const capitalizeFirstLetter = (word) => {
    return word.replace(/\b\w/g, char => char.toUpperCase());
}

/**
 * Formats everyword by deleting spaces and converting to lowercase letters
 * @param {String} word
 * @returns {String} formatted word
 */
export const formatWordToLowerCase = (word) => {
    return word.toLowerCase().replace(/\s+/g, '');
}

/**
 * Converts a date string from YYYY-MM-DD format to DD-MM-YYYY format.
 * This is done for the FetchAttendance Feature
 * @param {string} dateStr - Date string in YYYY-MM-DD format.
 * @returns {string} - Date string in DD-MM-YYYY format.
 */
export const convertDateToDDMMYYYY = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year}`;
};

export const getDDMMYYYYFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    
    // Extract the day, month, and year
    const day = String(date.getDate()).padStart(2, '0'); // Pad single-digit days with a leading zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

/**
 * Truncates Text
 * @param {String} text 
 * @param {Number} maxLength 
 * @returns {String}            -   Truncated Text
 */
export const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
};

export const getNextWeekwithWeekdays = (date) => {
    const getNextDay = (date, daysToAdd) => {
        const [day, month, year] = date.split('-').map(Number);
        const currentDate = new Date(year, month - 1, day);
    
        currentDate.setDate(currentDate.getDate() + daysToAdd);
    
        const nextDay = String(currentDate.getDate()).padStart(2, '0');
        const nextMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
        const nextYear = currentDate.getFullYear();
        const weekDay = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
    
        return `${nextDay}-${nextMonth}-${nextYear} (${weekDay})`;
    };
    
    const next7Days = [
        {
            date: date,
            weekDay: new Date(date.split('-').reverse().join('-')).toLocaleDateString('en-US', { weekday: 'long' }),
        }
    ];
    
    for (let i = 1; i < 7; i++) {
        const nextDate = getNextDay(date, i);
        next7Days.push({
            date: nextDate.split(' ')[0],
            weekDay: nextDate.split(' ')[1].replace(/[\(\)]/g, ''),
        });
    }
    
    return next7Days;
}

// Helper function to parse date string into Date object
export const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return new Date(`${year}-${month}-${day}`);
};

/**
 * Generates a random string
 *
 * @param {number} [length=20] - The length of the generated string. Default is 20 characters.
 * @returns {string} - A randomly generated string.
 */
export const generateRandomStringId = (length = 12) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};

export const getCountryPhoneCode = async () => {
    const userCountryCode = await getUserCountryCode();
    console.log('Code : ', userCountryCode);
    switch (userCountryCode) {
        case 'IN' :
            return '+91';

        case 'UK' :
            return '+44';

        default :
            return '+1';
    }
}